import React, { useState, useRef, useEffect } from 'react';
import './NavBar.css';
import Burger from './Burger';

const NavBar = ({homeSectionHeight}) => {
    const [showHeaderBackground, setShowHeaderBackground] = useState(false);

    const headerRef = useRef();

    function toggleHeader() {
        let headerHeight = headerRef.current.scrollHeight;
        if (window.pageYOffset < (homeSectionHeight - headerHeight)) {
            setShowHeaderBackground(false);
        } else {
            setShowHeaderBackground(true);
        }
      }

    const watchScroll = () => {
        window.addEventListener("scroll", toggleHeader);
    }

    const removeWatchScroll = () => {
        window.removeEventListener("scroll", toggleHeader);
    }

    const handleScroll = () => {
        watchScroll();
        return () => removeWatchScroll();
    }
    
    useEffect(() => {
        handleScroll();
    });

    return (
        <header
        id="header"
        role="banner"
        className={`header${showHeaderBackground ? "-show" : ""}`}
        ref={headerRef}
        >
            <div className="page-wrapper">
                <a className="logo" href="/">SH</a>
                <Burger/>
            </div>
        </header>
    )
}

export default NavBar;