import React from 'react';
import './projects.css';
import EurosPicture from '../../images/project_screenshots/euros_predictor_dashboard.png';

const Euros = () => {

    return (
        <div className="project-info-container">
            <div className="project-title-wrapper">
                <h1 className="project-title">Euro 2020 Predictor</h1>
                <div className="project-title-bar"></div>
                <a className="github-link" href="https://github.com/Stanhoucke/euros_predictor" target="_blank" rel="noreferrer">GitHub Repo</a>
            </div>
            <ul className="tech-list">
                <li className="tech-item">Python</li>
                <li className="tech-item">Flask</li>
                <li className="tech-item">JWT</li>
                <li className="tech-item">React</li>
                <li className="tech-item">Bootstrap</li>
                <li className="tech-item">PostgreSQL</li>
                <li className="tech-item">Psycopg</li>
                <li className="tech-item">RESTful Routes</li>
                <li className="tech-item">SQL</li>
                <li className="tech-item">HTML</li>
                <li className="tech-item">CSS</li>
            </ul>
            <figure className="project-image-wrapper">
                <figcaption className="project-description">
                    A football score predictor game, users predict the outcome of every match during the 2020 European Football Championship and crown their champion!
                </figcaption>
                <img className="project-image" src={EurosPicture} alt="Project screenshot"></img>
            </figure>
            <article className="project-brief">
                <h2 className="brief">Project Description</h2>
                <p>
                    A game based around the European Football Championship where users predict the scoreline of every match in the tournament and are awarded points based on the correctness of their predictions. Users compete in the Overall league table and can also create and join private leagues.
                </p>
                <p>
                    A user scores points by correctly predicting the outcome of a match, with a bonus point awarded for correctly predicting a team's number of goals scored. There is a maximum of 5 points awarded per game with the points breakdown as follows:
                </p>
                <ul>
                    <li><strong>5 points:</strong> Correctly guessing the exact score (e.g. user predicts Italy wins against Turkey 2 goals to 1, and Italy actually wins against Turkey 2 goals to 1).</li>
                    <li><strong>4 points:</strong> Correctly guessing the match outcome and one of the teams goals scored (e.g. user predicts Italy wins against Turkey 3 goals to 2, and Italy actually wins against Turkey 3 goals to 0).</li>
                    <li><strong>3 points:</strong> Correctly guessing the match outcome but neither of the teams goals scored (e.g. user predicts Italy draws with Turkey 1 goal to 1, and Italy actually draw with Turkey 2 goals to 2).</li>
                    <li><strong>1 points:</strong> Incorrectly guessing the match outcome but correctly predicting one of the teams goals scored (e.g. user predicts Italy wins against Turkey 3 goal to 2, and Italy actually draw with Turkey 1 goal to 1).</li>
                </ul>
                <p>
                    Users can submit scores up to the time that a round of fixtures starts, and make as many changes as desired until then. This gives users the option of predicting the entire tournament (55 matches) at once or to enter scores as the tournament progresses. For the group stages, group tables are shown to help the user check that the teams they would expect to qualify for the knockout stages do based on their match predictions. A dynamic third-placed teams table is also provided to see which 4 of the 6 third-placed teams qualify for the knockout stages.
                </p>
                <p>
                    All users are automatically added to the global Overall league where they can see their rank against all other users. The website also allows users to create private leagues so that they can directly compete against friends, family, colleagues, etc.
                </p>
            </article>
        </div>
    )

}

export default Euros;