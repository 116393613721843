import React from 'react';
import './ContactContainer.css';

const ContactContainer = () => {

    return (
        <section id="contact-container">
            <div id="contact-header-wrapper">
                <h1 id="contact-container-header">Contact</h1>
                <div id="contact-header-bar"></div>
            </div>
            <div className="contact-wrapper">
                <div className="contact-description">
                    <p id="contact-message" >
                        Have a question or want to work together? <br/>
                        Get in touch via one of the links below
                    </p>
                    <a id="email-button" href="mailto:stanhoucke@gmail.com">Get in touch</a>
                </div>
                <div className="contact-links">
                    <a href="https://www.linkedin.com/in/stanleyhoucke/" target="_blank" rel="noreferrer">LinkedIn</a>
                    <p>|</p>
                    <a href="https://github.com/Stanhoucke" target="_blank" rel="noreferrer">GitHub</a>
                </div>
            </div>
        </section>
    )
}

export default ContactContainer;