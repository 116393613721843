import React from 'react';
import './projects.css';
import SharesPicture from '../../images/project_screenshots/share_portfolio_tracker.png';

const Shares = () => {

    return (
        <div className="project-info-container">
            <div className="project-title-wrapper">
                <h1 className="project-title">Shares Portfolio Application</h1>
                <div className="project-title-bar"></div>
                <a className="github-link" href="https://github.com/Stanhoucke/portfolio_tracking_app" target="_blank" rel="noreferrer">GitHub Repo</a>
            </div>
            <ul className="tech-list">
                <li className="tech-item">Vue.js</li>
                <li className="tech-item">Node</li>
                <li className="tech-item">MongoDB</li>
                <li className="tech-item">Express</li>
                <li className="tech-item">Google Charts</li>
                <li className="tech-item">Javascript</li>
                <li className="tech-item">APIs</li>
                <li className="tech-item">HTML</li>
                <li className="tech-item">CSS</li>
            </ul>
            <figure className="project-image-wrapper">
                <figcaption className="project-description">
                A single user app designed to track the value and growth of a small share portfolio.
                The app also allows the user to search for shares by symbol, view their price trends, and add them to the portfolio.
                </figcaption>
                <img className="project-image" src={SharesPicture} alt="Project screenshot"></img>
            </figure>
            <article className="project-brief">
                <h2 className="brief">Project Brief</h2>
                <p>
                A local trader has come to you with a portfolio of shares. She wants to be able to analyse it more effectively. She has a small sample data set to give you and would like you to build a Minimum Viable Product that uses the data to display her portfolio so that she can make better decisions.
                </p>
                <h3>Aims</h3>
                <ul>
                    <li>To gain experience working in a team with other developers - to understand some of the challenges this creates and ways to overcome them</li>
                    <li>To have the opportunity to use Agile working methods</li>
                    <li>To become more confident using Git/GitHub for a multi person project</li>
                    <li>TDD - unit testing of models where appropriate</li>
                    <li>Members supporting each other to make sure everyone can get the most they can from the week</li>
                </ul>
                <h3>MVP</h3>
                <p>
                    A user should be able to:
                </p>
                <ul>
                    <li>View total current value</li>
                    <li>View individual and total performance trends</li>
                    <li>Retrieve a list of share prices from an external API and allow the user to add shares to her portfolio</li>
                    <li>View a chart of the current values in her portfolio</li>
                </ul>
                <h3>Extensions</h3>
                <ul>
                    <li>Speculation based on trends and further financial modelling using projections</li>
                </ul>
            </article>
        </div>
    )

}

export default Shares;