import React from 'react';
import './projects.css';
import PonyMazePicture from '../../images/project_screenshots/pony_maze.png';

const PonyMaze = () => {

    return (
        <div className="project-info-container">
            <div className="project-title-wrapper">
                <h1 className="project-title">Pony Maze</h1>
                <div className="project-title-bar"></div>
                <a className="github-link" href="https://github.com/Stanhoucke/pony_maze" target="_blank" rel="noreferrer">GitHub Repo</a>
            </div>
            <ul className="tech-list">
                <li className="tech-item">React</li>
                <li className="tech-item">APIs</li>
                <li className="tech-item">Styled Components</li>
                <li className="tech-item">Jest</li>
                <li className="tech-item">HTML</li>
                <li className="tech-item">CSS</li>
            </ul>
            <figure className="project-image-wrapper">
                <figcaption className="project-description">
                    Help the pony escape the maze guarded by a monster! Or let the AI take the reins 😱.
                </figcaption>
                <img className="project-image" src={PonyMazePicture} alt="Project screenshot"></img>
            </figure>
            <article className="project-brief">
                <h2 className="brief">Project Description</h2>
                <p>
                The aim of the challenge is to help a pony escape a maze guarded by a monster. This React web app allows the user to:
                </p>
                <ul>
                    <li>Create a new maze by choosing a My Little Pony character name, maze dimensions, and difficulty</li>
                    <li>Attempt to escape the maze by moving the pony using buttons or keys, highlight the exit route, or let an algorithm attempt to solve the maze automatically</li>
                    <li>Play again when the pony escapes or is caught by Domokun</li>
                </ul>
                <h3>About the Software</h3>
                <ul>
                    <li>The app was made using React and tested using the React Testing Library</li>
                    <li>API calls are made to create a maze, get the maze state, and make moves</li>
                    <li>The Styled Components library is used to create a dynamic and responsive representation of the maze</li>
                    <li>A Depth-First Search algorithm is used to highlight the pony's route to the maze exit</li>
                    <li>This yields the route for the automatic pony movement, triggered by useEffect hooks</li>
                </ul>
            </article>
        </div>
    )

}

export default PonyMaze;