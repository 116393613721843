import React from 'react';
import './Footer.css';

const Footer = () => {

    return (
        <footer id="footer">
            <p>&#169; 2021 <strong>STANLEY HOUCKE</strong></p>
        </footer>
    )
}

export default Footer;