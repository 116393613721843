import React from 'react';
import './projects.css';
import LeaderboardPicture from '../../images/project_screenshots/leaderboard_1.png';

const Leaderboard = () => {

    return (
        <div className="project-info-container">
            <div className="project-title-wrapper">
                <h1 className="project-title">5-a-side Leaderboard</h1>
                <div className="project-title-bar"></div>
                <a className="github-link" href="https://github.com/Simon2591990/Leaderboard-app" target="_blank" rel="noreferrer">GitHub Repo</a>
            </div>
            <ul className="tech-list">
                <li className="tech-item">React.js</li>
                <li className="tech-item">Java</li>
                <li className="tech-item">Spring</li>
                <li className="tech-item">PostgreSQL</li>
                <li className="tech-item">JPA</li>
                <li className="tech-item">Javascript</li>
                <li className="tech-item">REST APIs</li>
                <li className="tech-item">HTML</li>
                <li className="tech-item">CSS</li>
            </ul>
            <figure className="project-image-wrapper">
                <figcaption className="project-description">
                A leaderboard designed for a user to track and manage a team’s 5-a-side football results over multiple seasons
                </figcaption>
                <img className="project-image" src={LeaderboardPicture} alt="Project screenshot"></img>
            </figure>
            <article className="project-brief">
                <h2 className="brief">Project Brief</h2>
                <p>
                    The leaderboard web app would provide an extra layer of competition to friendly 5-a-side games, allowing players to show off their individual record.
                    The app would randomly generate two teams before the next upcoming game.
                    After each game played, match scores are uploaded with each player getting points based on the match result.
                    Over the course of a predefined season length, players can see their individual points tally.
                </p>
                <h3>MVP</h3>
                <ul>
                    <li>A user must be able to add 10 players to the roster, and define the number of matches to be played in a season</li>
                    <li>The roster should generate two teams of 5</li>
                    <li>The app should allow match scores to be added with the teams and results stored to be viewed later</li>
                    <li>Players’ scores should be tallied and shown in a leaderboard</li>
                </ul>
                <h3>Extensions</h3>
                <ul>
                    <li>Be able to have multiple team sizes</li>
                    <li>Be able to create more than 2 teams</li>
                    <li>Each player should be able to log in and view the leaderboard for their roster</li>
                    <li>Users should be able to update their details</li>
                </ul>
                <h3>Further Extensions</h3>
                <ul>
                    <li>The app should have a stats page to be able to track more detailed information such as goals scored, conceded</li>
                    <li>Players should be awarded badges to count season wins, and/or have a crown next to their name to show their leader status</li>
                    <li>Add a ‘person of the match’ award which is voted on by the players</li>
                    <li>Players should be able to join multiple rosters</li>
                    <li>Add sweepstake functionality with the winner of the season taking the whole pot</li>
                </ul>
            </article>
        </div>
    )

}

export default Leaderboard;