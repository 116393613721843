import React from 'react';
import './ProjectContainer.css'

const ProjectContainer = () => {

    return (
        <>
        <section id="project-container">
            <div id="project-header-wrapper">
                <h1 id="project-header">Projects</h1>
                <div id="project-header-bar"></div>
            </div>
            <div className="project-list">
                <div id="gym" className="project-item">
                    <h3 className="project-name" >Gym Management App</h3>
                    <p className="project-tech">Python | Flask | PostgreSQL</p>
                    <a className="learn-more-button" href="/projects/gym">LEARN MORE</a>
                </div>
                <div id="shares" className="project-item">
                    <h3 className="project-name" >Personal Stock Portfolio</h3>
                    <p className="project-tech">Vue.js | Node | MongoDB</p>
                    <a className="learn-more-button" href="/projects/shares">LEARN MORE</a>
                </div>
                <div id="leaderboard" className="project-item">
                    <h3 className="project-name" >Leaderboard</h3>
                    <p className="project-tech">Java | Spring | React</p>
                    <a className="learn-more-button" href="/projects/leaderboard">LEARN MORE</a>
                </div>
                <div id="euros" className="project-item">
                    <h3 className="project-name" >Euro Predictor</h3>
                    <p className="project-tech">Python | JWT | React | PostgreSQL</p>
                    <a className="learn-more-button" href="/projects/euros">LEARN MORE</a>
                </div>
                <div id="pony-maze" className="project-item">
                    <h3 className="project-name" >Pony Maze</h3>
                    <p className="project-tech">React | API | Jest</p>
                    <a className="learn-more-button" href="/projects/pony_maze">LEARN MORE</a>
                </div>
                <div id="golden-shoe" className="project-item">
                    <h3 className="project-name" >Golden Shoe</h3>
                    <p className="project-tech">Java | Spring | React | MongoDB</p>
                    <a className="learn-more-button" href="/projects/golden_shoe">LEARN MORE</a>
                </div>
            </div>
        </section>
        
        <div className="spacer waves-grey-to-blue "></div>
        </>
    )
}

export default ProjectContainer;