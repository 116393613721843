import React from 'react';
import './projects.css';
import GymPicture from '../../images/project_screenshots/gym_web_app.png';

const Gym = () => {

    return (
        <div className="project-info-container">
            <div className="project-title-wrapper">
                <h1 className="project-title">Gym Web App</h1>
                <div className="project-title-bar"></div>
                <a className="github-link" href="https://github.com/Stanhoucke/gym_web_app" target="_blank" rel="noreferrer">GitHub Repo</a>
            </div>
            <ul className="tech-list">
                <li className="tech-item">Python</li>
                <li className="tech-item">Flask</li>
                <li className="tech-item">PostgreSQL</li>
                <li className="tech-item">Psycopg</li>
                <li className="tech-item">RESTful Routes</li>
                <li className="tech-item">SQL</li>
                <li className="tech-item">HTML</li>
                <li className="tech-item">CSS</li>
            </ul>
            <figure className="project-image-wrapper">
                <figcaption className="project-description">
                    A management facing web application to manage gym members and classes.
                    Using a cross country ski club as the client with Harry Potter characters as an example.
                </figcaption>
                <img className="project-image" src={GymPicture} alt="Project screenshot"></img>
            </figure>
            <article className="project-brief">
                <h2 className="brief">Project Brief</h2>
                <p>
                    A local gym has asked you to build a piece of software to help them to manage memberships, and register members for classes.
                </p>
                <p>
                    It must <strong>NOT</strong> use:
                </p>
                <ul>
                    <li>Any Object Relational Mapper (e.g. ActiveRecord)</li>
                    <li>JavaScript. At all. Don't even think about it.</li>
                    <li>Any pre-built CSS libraries, such as Bootstrap.</li>
                    <li>Authentication. Assume that the user already has secure access to the app.</li>
                </ul>
                <h3>MVP</h3>
                <ul>
                    <li>The app should allow the gym to create and edit Members</li>
                    <li>The app should allow the gym to create and edit Classes</li>
                    <li>The app should allow the gym to book members on specific classes</li>
                    <li>The app should show a list of all upcoming classes</li>
                    <li>The app should show all members that are booked in for a particular class</li>
                </ul>
                <h3>Extensions</h3>
                <ul>
                    <li>Anything else you would like to add</li>
                </ul>
            </article>

        </div>
    )

}

export default Gym;