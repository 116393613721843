import React from 'react';
import './AboutContainer.css'
import profilePicture from '../images/me_and_dhugal.jpeg'

const AboutContainer = () => {

    return (
        <section id="about-container">
            <div id="about-header-wrapper">
                <h1 id="about-header">About</h1>
                <div id="about-header-bar"></div>
            </div>
            <div className="about-wrapper">
                <article className="my-description">
                    <div id="description-grid-item">
                        <img src={profilePicture} id="profile-picture" alt="Me and Dhugal"/>
                        <div className="my-description-text">
                            <h3>Who's This Guy?</h3>
                            <p>
                                I grew up on the outskirts of Geneva, Switzerland and have called Edinburgh, Scotland home since 2009.
                                I've developed a passion for coding and problem solving that I put to good use in fun, meaningful projects.
                            </p>
                        </div>
                    </div>
                </article>

                <article className="my-values">
                    <div className="values-item">
                        <span className="material-icons">verified</span>
                        <h3>High Quality</h3>
                        <p>I prioritise delivering high quality products that exceed client expectations</p>
                    </div>
                    <div className="values-item">
                        <span className="material-icons">local_library</span>
                        <h3>Learning</h3>
                        <p>I love to learn and am continuously looking to improve my work</p>
                    </div>
                    <div className="values-item">
                        <span className="material-icons">visibility</span>
                        <h3>Attention to Detail</h3>
                        <p>Every detail matters whether it's for performance, aesthetics, or user experience</p>
                    </div>
                    <div className="values-item">
                        <span className="material-icons">group_add</span>
                        <h3>Inclusive</h3>
                        <p>Tech is for everyone. I want my work to be as inclusive and accessible as possible</p>
                    </div>
                </article>
            </div>

            <div className="wave">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill-blue"></path>
                </svg>
            </div>

            <div className="wave-bottom">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill-grey"></path>
                </svg>
            </div>

        </section>
    )
}

export default AboutContainer;