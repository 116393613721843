import React from 'react';
import './projects.css';
import GoldenShoePicture from '../../images/project_screenshots/golden_shoe.png';

const GoldenShoe = () => {

    return (
        <div className="project-info-container">
            <div className="project-title-wrapper">
                <h1 className="project-title">Golden Shoe</h1>
                <div className="project-title-bar"></div>
                <a className="github-link" href="https://github.com/Stanhoucke/golden_shoe" target="_blank" rel="noreferrer">GitHub Repo</a>
            </div>
            <ul className="tech-list">
                <li className="tech-item">React</li>
                <li className="tech-item">Java</li>
                <li className="tech-item">Spring</li>
                <li className="tech-item">MongoDB</li>
                <li className="tech-item">RESTful Routes</li>
                <li className="tech-item">Postman</li>
                <li className="tech-item">Styled Components</li>
                <li className="tech-item">Cypress</li>
                <li className="tech-item">HTML</li>
                <li className="tech-item">CSS</li>
            </ul>
            <figure className="project-image-wrapper">
                <figcaption className="project-description">
                    An e-commerce website for a shoe company, Golden Shoe.
                </figcaption>
                <img className="project-image" src={GoldenShoePicture} alt="Project screenshot"></img>
            </figure>
            <article className="project-brief">
                <h2 className="brief">Project Description</h2>
                <p>
                    The app uses:
                </p>
                <ul>
                    <li>A React frontend, Spring Boot backend, and MongoDB database.</li>
                    <li>The JUnit and Cypress testing suites for unit, integration, and end-to-end testing.</li>
                    <li>Postman for testing API endpoints.</li>
                </ul>
                <a className="project-link" href="https://documenter.getpostman.com/view/17606355/UUxuiVcB" target="_blank">View the API Documentation</a>
            </article>
        </div>
    )

}

export default GoldenShoe;