import React from 'react';
import styled from 'styled-components';

const Ul = styled.ul`
padding-left: 0;
width: 100%;
list-style: none;
display: flex;
justify-content: flex-end;  

  @media (max-width: 768px) {
    flex-direction: column;
    list-style: none;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    right: 0;
    margin: 0;
    padding: 3.5em 0em;
    transition: transform 0.3s ease-in-out;
    li {
      height: 25vh;
    }
  }
`;

const SideNav = ({ open, handleBurgerClick }) => {
  return (
            <Ul className="nav-bar" open={open}>
                <li className="nav-item">
                    <a href="/#home-container" className="nav-link" onClick={handleBurgerClick}>Home</a>
                </li>
                <li className="nav-item">
                    <a href="/#about-container" className="nav-link" onClick={handleBurgerClick}>About</a>
                </li>
                <li className="nav-item">
                    <a href="/#project-container" className="nav-link" onClick={handleBurgerClick}>Projects</a>
                </li>
                <li className="nav-item">
                    <a href="/#contact-container" className="nav-link" onClick={handleBurgerClick}>Contact</a>
                </li>
            </Ul>
  )
}

export default SideNav