import React, { useState } from 'react'
import './App.css';
import NavBar from './components/NavBar'
import HomeContainer from './containers/HomeContainer'
import AboutContainer from './containers/AboutContainer'
import ProjectContainer from './containers/ProjectContainer'
import ContactContaienr from './containers/ContactContainer'
import Footer from './components/Footer';
import { BrowserRouter as Router, Route, Switch }  from 'react-router-dom';
import Gym from './components/projects/gym';
import Shares from './components/projects/shares';
import Leaderboard from './components/projects/leaderboard';
import Euros from './components/projects/euros';
import PonyMaze from './components/projects/ponyMaze';
import GoldenShoe from './components/projects/goldenShoe';

function App() {
  const [homeSectionHeight, setHomeSectionHeight] = useState(450);

  const getHomeSectionHeight = (height) => {
    setHomeSectionHeight(height);
  }

  return (
    <div className="App">
      <Router>
        <NavBar homeSectionHeight={homeSectionHeight} />
        <Switch>
          <Route
            exact
            path="/"
            render={() => (
              <>
                <HomeContainer getHomeSectionHeight={getHomeSectionHeight} />
                <AboutContainer />
                <ProjectContainer />
                <ContactContaienr />
              </>
            )}
          />
          <Route path="/projects/gym" render={() => <Gym />} />
          <Route path="/projects/shares" render={() => <Shares />} />
          <Route path="/projects/leaderboard" render={() => <Leaderboard />} />
          <Route path="/projects/euros" render={() => <Euros />} />
          <Route path="/projects/pony_maze" render={() => <PonyMaze />} />
          <Route path="/projects/golden_shoe" render={() => <GoldenShoe />} />
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
