import React, { useEffect, useRef } from 'react';
import './HomeContainer.css';
import KUTE from 'kute.js';

const HomeContainer = ({ getHomeSectionHeight }) => {
    const homeSectionRef = useRef();

    useEffect(() => {
        getHomeSectionHeight(homeSectionRef.current.scrollHeight);
        const tween = KUTE.fromTo(
            '#blob1',
            {path: '#blob1'},
            {path: '#blob3'},
            {repeat: 999, duration: 6000, yoyo: true}
        )
    
        tween.start()
    }, [])


    return (
      <section id="home-container" ref={homeSectionRef}>
        <h1 id="title">Stanley Houcke</h1>
        <h2 id="intro">Full Stack Developer | Software Engineer</h2>
        <a id="intro-button" href="#about-container">
          View my work
        </a>

        <div id="blob-container">
          <svg
            id="visual"
            viewBox="0 0 900 450"
            width="900"
            height="450"
            //   xmlns="http://www.w3.org/2000/svg"
            //   xmlns:xlink="http://www.w3.org/1999/xlink"
            version="1.1"
          >
            <g transform="translate(471.04906613233095 262.05045039159234)">
              <path
                id="blob1"
                d="M148 -190.3C178.2 -151.1 179.7 -91.5 188.5 -34.3C197.3 22.9 213.4 77.7 194.6 114.3C175.8 151 122.1 169.5 75.8 169.4C29.6 169.3 -9.3 150.7 -54.7 139.1C-100.2 127.4 -152.3 122.7 -190.4 92.6C-228.6 62.4 -252.8 6.8 -243.4 -42.3C-233.9 -91.3 -190.9 -133.7 -144.7 -170.1C-98.6 -206.5 -49.3 -236.7 4.8 -242.5C58.9 -248.2 117.8 -229.4 148 -190.3"
                fill="#D3D3D3"
              ></path>
            </g>
            <g
              transform="translate(416.9291041760485 234.1022468433106)"
              style={{ visibility: "hidden" }}
            >
              <path
                id="blob3"
                d="M132.3 -161.9C162.9 -131.9 173.6 -82.2 188.8 -28.5C204 25.2 223.8 82.9 202 112.9C180.3 142.9 117 145.2 63.3 157.8C9.6 170.4 -34.5 193.3 -68.3 183C-102.1 172.8 -125.5 129.4 -135.5 89.3C-145.5 49.2 -142 12.4 -143.5 -33.4C-145 -79.1 -151.6 -133.8 -128.5 -165.2C-105.4 -196.6 -52.7 -204.8 -1 -203.7C50.8 -202.5 101.6 -192 132.3 -161.9"
                fill="#D3D3D3"
              ></path>
            </g>
          </svg>
        </div>
      </section>
    );
}

export default HomeContainer;